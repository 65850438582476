<template>
    <div id="app">
        <v-app>
            <div>
                <!-- App Bar -->
                <v-app-bar app color="primary" dark class="appbar" >
                    <!-- Hamburger Menu Icon -->
                    <v-app-bar-nav-icon @click="drawer = !drawer" />

                    <!-- Logo -->
                    <img src="https://bouncy-vibes.co.uk/bvplayer/play.gif" class="logo" width="20px" height="20px" />
                    <v-toolbar-title>Bouncy Vibes</v-toolbar-title>

                    <!-- Spacer pushes content to the right -->
                    <v-spacer></v-spacer>

                    <!-- Notifications Dropdown -->
                    <v-menu offset-y v-if="loggedIn">
                        <template v-slot:activator="{ props }">
                            <v-btn v-if="notifications.length" icon v-bind="props">
                                <v-badge v-if="notifications.length" :content="notifications.length" color="red" overlap
                                    class="notification-badge">
                                    <v-icon>mdi-bell</v-icon>
                                </v-badge> 
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item 
                                v-for="(item) in notifications" 
                                :key="item.id" 
                                :value="item.id"
                                @click="handleNotificationClick(item)"
                                :class="{ 'unread': !item.read }"
                            >
                                <v-list-item-title class="d-flex align-center">
                                    <v-icon :color="getIconColor(item.type)" class="me-2">
                                        {{ getIconForType(item.type) }}
                                    </v-icon>
                                    
                                    <v-chip 
                                        :color="item.read ? 'grey-lighten-2' : 'primary'"
                                        class="notification-chip"
                                    >
                                        {{ item.from }} {{ getNotificationText(item.type) }}
                                    </v-chip>

                                    <v-spacer></v-spacer>
                                    
                                    <v-icon 
                                        v-if="!item.read" 
                                        size="small" 
                                        color="blue"
                                        class="me-2"
                                    >
                                        mdi-circle
                                    </v-icon>
                                    
                                    <v-icon 
                                        @click.stop="clearNotifications(item.id)" 
                                        size="small"
                                        color="grey"
                                    >
                                        mdi-close
                                    </v-icon>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- Username and Profile -->
                    <div class="d-flex align-center" v-if="loggedIn">
                        <v-avatar class="mx-2">
                            <img :src="userdata.profileimage" width="25" height="25" />
                        </v-avatar>
                        <span>Welcome, {{ userdata.username }}</span>
                    </div>

                    <!-- Logout Button -->
                    <v-btn icon @click="logout" v-if="loggedIn">
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>
                    <v-btn @click="this.$router.push('/login')" v-else class="mx-2 loginBtn">
                        Login Here
                        <v-icon>mdi-login</v-icon>
                    </v-btn>
                </v-app-bar>


                <!-- Navigation Drawer -->
                <v-navigation-drawer v-model="drawer" app temporary class="sideNav" width="200">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-start align-center menuTabs" v-if="loggedIn"
                            @click="this.$router.push(`/user/${userdata.username}`)">
                            <img :src="userdata.profileimage" class="menuPic" />
                            <v-list-item-title class="menuText">My Profile</v-list-item-title>
                        </div>
                        <div class="d-flex flex-row justify-start align-center menuTabs" @click="showPanel('home')">
                            <v-icon>mdi-home</v-icon>
                            <v-list-item value="home" class="menuText">Home</v-list-item>
                        </div>

                        <div class="d-flex flex-row justify-start align-center menuTabs" @click="showPanel('newsfeed')">
                            <v-icon>mdi-mail</v-icon>
                            <v-list-item value="feed" class="menuText">News Feed</v-list-item>
                        </div>
                        <div class="d-flex flex-row justify-start align-center menuTabs" @click="showPanel('schedule')">
                            <v-icon>mdi-calendar</v-icon>
                            <v-list-item value="schedule" class="menuText">Schedule</v-list-item>
                        </div>
                        <div class="d-flex flex-row justify-start align-center menuTabs"
                            @click="this.$router.push('/chat')">
                            <v-icon>mdi-chat</v-icon>
                            <v-list-item value="chat" class="menuText">Chat</v-list-item>
                        </div>
                        <div class="d-flex flex-row justify-start align-center menuTabs" @click="logout"
                            v-if="loggedIn">
                            <v-icon>mdi-logout</v-icon>
                            <v-list-item value="logout" class="menuText">Logout</v-list-item>
                        </div>
                        <div v-if="admin" class="d-flex flex-row justify-start align-center menuTabs"
                            @click="showPanel('admin')">
                            <v-icon>mdi-cog</v-icon>
                            <v-list-item value="admin" class="menuText">Admin</v-list-item>
                        </div>
                        <div class="d-flex flex-row justify-start align-center menuTabs" @click="showPanel('Tickets')">
                            <v-icon>mdi-ticket</v-icon>
                            <v-list-item value="Ticket" class="menuText">Ticket Support</v-list-item>
                        </div>
                    </div>
                    <v-footer class="navfoot">
                        Made with ❤️ By ButchyDutchy &copy; 2024 - All Rights Reserved
                    </v-footer>
                </v-navigation-drawer>

                <!-- Main Content -->
                <v-main class="v-main">
                    <router-view v-slot="{ Component }">
                        <component :is="Component" :player="player" />
                    </router-view>
                </v-main>
                <!-- Footer -->
                <v-footer class="fixed-footer">
                    <div class="pcontainer d-flex justify-space-evenly align-center w-100">
                        <!-- Left Section: Player Name -->
                        <div class="PName d-flex align-center" justify="start">
                            <v-icon>mdi-headphones</v-icon>
                            <span class="title">{{ player.name }}</span>
                        </div>

                        <!-- Middle Section: Play Controls -->
                        <div class="PControls d-flex align-center" justify="center">
                            <v-btn icon @click="playMusic">
                                <v-icon>{{ player.playing ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                            </v-btn>
                        </div>

                        <!-- Right Section: Player Stats -->
                        <div class="PStats d-flex align-center" justify="end">
                            <v-icon>mdi-account</v-icon>
                            <span class="title">DJ: {{ player.dj }}</span>

                            <v-icon>mdi-music</v-icon>
                            <span class="title">Bitrate: {{ player.bitrate }}kbps</span>

                            <v-icon>mdi-account-multiple</v-icon>
                            <span class="title">Listeners: {{ player.listeners }}</span>
                        </div>
                    </div>
                </v-footer>
            </div>
        </v-app>
    </div>
</template>

<script>
import authService from "../services/authService";

export default {
    name: "App",
    data() {
        return {
            drawer: false, // Controls the drawer visibility
            notifications: [],
            menu: false, // Controls the menu visibility
            userdata: {},
            audioElement: null, // Persistent reference to the audio element


            loggedIn: false,
            admin: false,
            player: {
                name: 'SyncSocial',
                host: 'https://bouncy-host.co.uk/api/nowplaying/1',
                src: "",
                volume: 100,
                bitrate: 320,
                listeners: 0,
                dj: "",
                playing: false,
                raw: null,

            },
        };
    },
    mounted() {
        authService
            .getUserData()
            .then((response) => {
                this.userdata = response.data;
                this.loggedIn = true;
                if (this.userdata.role === "Admin") {
                    this.admin = true;
                }
            })
            .catch((error) => {
                console.error(error);
            });
        this.fetchRadioData();
        authService.userNotifications().then((response) => {
            this.notifications = response.data;
        });
        setInterval(this.fetchRadioData, 30000); // Fetch radio data every 30 seconds
        setInterval(() => {
            authService.userNotifications().then((response) => {
                this.notifications = response.data;
            });
        }, 60000);
    },
    methods: {
        fetchRadioData() {
            fetch(this.player.host)
                .then(response => response.json())
                .then(data => {
                    this.player.listeners = data.listeners.current;
                    this.player.src = data.station.listen_url;
                    this.player.name = data.station.name;
                    this.player.dj = data.now_playing.streamer || "BVR Auto Dj"; // Fallback for empty DJ name
                    this.player.raw = data;
                })
                .catch(error => {
                    console.error('Error fetching radio data:', error);
                });
        },
        logout() {
            localStorage.removeItem("token");
            this.loggedIn = false;
            this.$router.push("/logout");
        },
        clearNotifications(id) {
            this.notifications = this.notifications.filter((item) => item.id !== id);
        },
        playMusic() {
            try {
                // Initialize the audio element if not already done
                if (!this.audioElement) {
                    this.audioElement = new Audio(this.player.src);
                    this.audioElement.loop = true; // Optional: Loop the audio
                }

                // If the audio is playing, pause it
                if (this.player.playing) {
                    this.audioElement.pause();
                    this.player.playing = false;
                } else {
                    // Otherwise, play the audio
                    this.audioElement.play()
                        .then(() => {
                            this.player.playing = true;
                        })
                        .catch((error) => {
                            console.error("Error playing audio:", error);
                        });
                }
            } catch (error) {
                console.error("Error in playMusic:", error);
            }
        },
        showPanel(panel) {
            this.$router.push(`/${panel}`);
        },
        getIconForType(type) {
            const icons = {
                system: 'mdi-information',
                follower: 'mdi-account-plus',
                message: 'mdi-email',
                ticket: 'mdi-ticket',
                comment: 'mdi-comment',
                like: 'mdi-thumb-up',
                post: 'mdi-newspaper'
            }
            return icons[type] || 'mdi-bell'
        },

        getIconColor(type) {
            const colors = {
                system: 'blue',
                follower: 'green',
                message: 'purple',
                ticket: 'orange',
                comment: 'cyan',
                like: 'pink',
                post: 'indigo'
            }
            return colors[type] || 'grey'
        },

        getNotificationText(type) {
            const texts = {
                system: 'sent a system notification',
                follower: 'started following you',
                message: 'sent you a message',
                ticket: 'created a ticket',
                comment: 'commented on your post',
                like: 'liked your post',
                post: 'made a new post'
            }
            return texts[type] || 'notification'
        },

        handleNotificationClick(item) {
            // Mark as read
            this.markAsRead(item.id)

            // Navigate based on type
            switch(item.type) {
                case 'post':
                    this.$router.push(`/profile/${item.userId}/post/${item.postId}`)
                    break
                case 'follower':
                    this.$router.push(`/profile/${item.userId}`)
                    break
                case 'message':
                    this.$router.push(`/messages/${item.messageId}`)
                    break
                case 'comment':
                case 'like':
                    this.$router.push(`/post/${item.postId}`)
                    break
                case 'ticket':
                    this.$router.push(`/tickets/${item.ticketId}`)
                    break
            }
        }
    },
};
</script>

<style scoped>
.sideNav {
    background-color: #212121 !important;
    color: white;
}

.PanelLogo {
    margin: 10px 0px;
    cursor: pointer;
}

.v-main {
    background-image: url(https://bouncy-vibes.co.uk/images/bg.jpg) !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    color: white;
    text-align: center;

    /* Add padding to prevent overlap with footer */
    padding-bottom: 80px;
    /* Adjust this value to match the footer height */
}

.player-container {
    width: 100%;
    background-color: #212121;
    color: white;
}

.PlayerName {
    flex: 1;
}

.PlayerControls {
    flex: 0;
    justify-content: center;
}

.PlayerStats {
    flex: 2;
    justify-content: flex-end;
    margin-right: 200px;
}

.PlayerStats .d-flex {
    margin-left: 20px;
}

.navfoot {
    position: fixed !important;
    bottom: 0 !important;
    width: 100%;
    background-color: #21212100;
    color: white;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    font-size: 10px;
}

.menuText {
    font-size: 14px !important;
    color: white !important;
    font-weight: bold !important;
}

.menuTabs {
    margin: 0px 5px 5px 5px;
    border-radius: 5px;
    padding: 1px;
}

.menuTabs:hover {
    background-color: #0089E3;
    color: white;
    cursor: pointer;
}

.menuPic {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-right: 10px;
}

.fixed-footer {
    background-image: url(https://bouncy-vibes.co.uk/uploads/pages/ccf1f45-ws_Colorful_Equalizer_852x480.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: bottom;
    color: white !important;
    padding: 10px !important;
    width: 100% !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
    bottom: 0 !important;
    position: fixed !important;
    z-index: 1000 !important;

    /* Flexbox layout for proper alignment */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Distribute sections evenly */
    flex-wrap: nowrap;
    /* Prevent wrapping for stats */
    height: 60px !important;
    /* Fixed height for consistency */
}

/* Adjust for smaller screens */
@media (max-width: 600px) {
    .fixed-footer {
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center align content */
        height: auto;
        /* Dynamic height */
        padding: 15px;
    }

    .fixed-footer .PName,
    .fixed-footer .PControls,
    .fixed-footer .PStats {
        flex: 1 1 100%;
        /* Take full width on small screens */
        margin-bottom: 5px;
        /* Add spacing between stacked items */
        text-align: center;
    }
}

.fixed-footer .PName {
    flex: 1;
    /* Left section */
    text-align: left;
    /* Align left for larger screens */
}

.fixed-footer .PControls {
    flex: 0;
    /* Center section (play/pause) */
    text-align: center;
}

.fixed-footer .PStats {
    flex: 1;
    /* Right section */
    text-align: right;
    /* Align right for larger screens */
    display: flex;
    justify-content: flex-end;
    /* Keep stats on one line */
    gap: 10px;
    /* Add spacing between stats */
}

.fixed-footer .PStats span,
.fixed-footer .PStats v-icon {
    font-size: 12px;
    /* Ensure stats are compact */
}

.unread {
    background-color: rgba(var(--v-theme-primary), 0.05);
}

.notification-chip {
    cursor: pointer;
}


</style>
