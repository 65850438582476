<template>
    <div class="ticket-system">
        <h2>My Tickets</h2>

        <!-- Button to Open Create Ticket Dialog -->
        <v-btn color="primary" @click="openCreateTicketDialog">Create Ticket</v-btn>

        <!-- Ticket List -->
        <v-data-table dark :items="tickets" :headers="headers" item-value="_id" class="elevation-1" v-if="tickets.length > 0"
            :item-class="getRowClass">
            <template #[`item.title`]="{ item }">
                <span>{{ item.title }}</span>
            </template>
            <template #[`item.priority`]="{ item }">
                <span>{{ item.priority }}</span>
            </template>
            <template #[`item.status`]="{ item }">
                <span>{{ item.status }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn color="primary" :disabled="item.status === 'Closed'" @click="openTicketDialog(item)">
                    View
                </v-btn>
                <v-btn color="red" :disabled="item.status === 'Closed'" @click="closeTicket(item._id)">
                    Close
                </v-btn>
            </template>
        </v-data-table>

        <v-row v-else>
            <v-col>
                <v-alert type="info">No tickets found</v-alert>
            </v-col>
        </v-row>

        <!-- Ticket Dialog -->
        <v-dialog v-model="dialog" max-width="600px" transition="fade-transition">
            <v-card class="ticket-dialog" elevation="2">
                <v-card-title class="headline pb-2">
                    {{ selectedTicket.title }}
                </v-card-title>
                
                <v-card-subtitle class="d-flex align-center py-2">
                    <v-chip small :color="getPriorityColor" label>
                        {{ selectedTicket.priority }}
                    </v-chip>
                    <v-divider vertical class="mx-2"></v-divider>
                    <v-chip small :color="getStatusColor" label>
                        {{ selectedTicket.status }}
                    </v-chip>
                </v-card-subtitle>

                <v-card-text class="pt-4">
                    <div class="description-section mb-6">
                        <div class="text-subtitle-1 font-weight-medium mb-2">Description</div>
                        <p class="text-body-2">{{ selectedTicket.description }}</p>
                    </div>

                    <v-divider class="my-4"></v-divider>

                    <div class="messages-section">
                        <div class="text-subtitle-1 font-weight-medium mb-3">Messages</div>
                        <v-list class="message-list pa-0" flat>
                            <v-list-item v-for="(message, index) in selectedTicket.messages" 
                                        :key="index"
                                        class="message-item rounded-lg mb-2"
                                        :class="{'my-message': isMyMessage(message.sender)}">
                                <v-list-item-content>
                                    <v-list-item-title class="text-subtitle-2 font-weight-medium">
                                        {{ message.sender }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-body-2 mt-1">
                                        {{ message.content }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>

                    <v-textarea v-model="newMessage" 
                            label="Add a message" 
                            filled
                            auto-grow
                            rows="3"
                            class="message-input mt-4"
                            hide-details>
                    </v-textarea>
                </v-card-text>

                <v-card-actions class="pa-4">
                    <v-alert v-if="Messageresponse" 
                            type="info" 
                            dense 
                            text
                            class="ma-0">
                        {{ Messageresponse }}
                    </v-alert>
                    <v-spacer></v-spacer>
                    <v-btn text 
                        @click="dialog = false"
                        class="text-caption">
                        Close
                    </v-btn>
                    <v-btn color="primary" 
                        @click="sendMessage(selectedTicket._id)"
                        class="ml-2"
                        elevation="0">
                        <v-icon left small>mdi-send</v-icon>
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create Ticket Dialog -->
        <v-dialog v-model="createTicketDialog" max-width="600px">
            <v-card>
                <v-card-title>Create New Ticket</v-card-title>
                <v-card-text>
                    <v-text-field v-model="ticket.title" label="Title" outlined required />
                    <v-textarea v-model="ticket.description" label="Description" outlined required />
                    <v-select v-model="ticket.priority" :items="priorities" label="Priority" outlined required />
                </v-card-text>
                <v-card-actions>

                    <v-alert v-if="Messageresponse" type="info">{{ Messageresponse }}</v-alert>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="createTicket">Create</v-btn>
                    <v-btn text @click="createTicketDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import authService from "../services/authService";

export default {
    data() {
        return {
            ticket: {
                title: "",
                description: "",
                priority: "Minor",
            },
            priorities: ["Minor", "Moderate", "Major"],
            tickets: [],
            userdata: {}, // Stores user data
            headers: [
                { title: "Title", key: "title", align: "start" },
                { title: "Priority", key: "priority", align: "start" },
                { title: "Status", key: "status", align: "start" },
                { title: "Actions", key: "actions", align: "end", sortable: false },
            ],
            dialog: false,
            createTicketDialog: false, // Controls create ticket dialog visibility
            selectedTicket: null,
            newMessage: "",
            Messageresponse: "",
        };
    },
    computed: {
        getPriorityColor() {
            const colors = {
                high: 'error',
                medium: 'warning',
                low: 'success'
            };
            return colors[this.selectedTicket.priority.toLowerCase()] || 'grey';
        },
        getStatusColor() {
            const colors = {
                open: 'primary',
                pending: 'warning',
                closed: 'grey'
            };
            return colors[this.selectedTicket.status.toLowerCase()] || 'grey';
        }
    },
    methods: {
        getRowClass(item) {
            if (item.status === "Open") {
                return "row-open"; // Class for open status
            } else if (item.status === "Closed") {
                return "row-closed"; // Class for closed status
            }
            return ""; // Default class
        },
        openCreateTicketDialog() {
            this.createTicketDialog = true; // Opens the create ticket dialog
        },
        async createTicket() {
            try {
                const ticketData = {
                    ...this.ticket, // Only send the ticket fields: title, description, and priority
                };
                const res = await authService.createTicket(ticketData);
                this.tickets.unshift(res.data.ticket); // Add new ticket to the list
                this.createTicketDialog = false; // Close the dialog
                this.ticket = { title: "", description: "", priority: "Minor" }; // Reset form
                console.log("ticket sending", res.data);

            } catch (err) {
                if (err.response) {
                    this.Messageresponse = err.response.data.message;
                } else {
                    this.Messageresponse = "An error occurred. Please try again later.";
                }
                //delete this.Messageresponse; after 5 seconds
                setTimeout(() => {
                    this.Messageresponse = "";
                }, 5000);
            }
        },
        async fetchTickets() {
            try {
                const response = await authService.getUserTickets();
                this.tickets = response.data.tickets;
            } catch (error) {
                console.error("Failed to fetch tickets:", error);
            }
        },
        openTicketDialog(ticket) {
            this.selectedTicket = ticket;
            this.dialog = true;
        },
        async sendMessage(ticketId) {
            if (this.newMessage.trim() === "") return;

            try {
                await authService.addMessage(ticketId, {
                    sender: "user", // Replace with role from the current user's data
                    content: this.newMessage,
                });
                this.selectedTicket.messages.push({
                    sender: "user",
                    content: this.newMessage,
                    timestamp: new Date(),
                });
                this.newMessage = "";
            } catch (error) {
                console.error("Failed to send message:", error);
            }
        },
        isMyMessage(sender) {
            // Replace with your logic to determine if the message is from the current user
            return sender === 'currentUser';
        }
    },
    async mounted() {
        const userid = localStorage.getItem("user-id");
        if (!userid) {
            console.error("User ID not found in local storage");
            return;
        }

        try {
            const response = await authService.getUserData(userid);
            this.userdata = response.data;
            localStorage.setItem("userdata", JSON.stringify(response.data));
            this.fetchTickets(); // Fetch tickets after user data is available
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    },
};
</script>
<style scoped>
.row-open {
    background-color: #56e176 !important; /* Light green for open status */
  }
  
  .row-closed {
    background-color: #cf4954 !important; /* Light red for closed status */
  }
  
  .elevation-1 .v-data-table__wrapper .row-open:hover,
  .elevation-1 .v-data-table__wrapper .row-closed:hover {
    background-color: #ffffff !important; /* Change hover color */
  }

.ticket-system {
    padding: 20px;
    /* Adjust as needed */
    margin: 0;
    /* Ensure no extra margins */
    min-height: 100vh;
    /* Ensure it stretches full height */
}

.v-data-table {
    margin: 0 auto;
    /* Center the table */
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


.main-content {
    flex: 1;
    /* Ensure it takes up available space */
}

.ticket-dialog {
    border-radius: 8px;
}

.message-list {
    max-height: 300px;
    overflow-y: auto;
}

.message-item {
    background-color: #f5f5f5;
    margin-bottom: 8px;
    border-radius: 8px !important;
    transition: background-color 0.2s;
}

.my-message {
    background-color: #e3f2fd;
}

.message-item:hover {
    background-color: #eeeeee;
}

.my-message:hover {
    background-color: #bbdefb;
}

.message-input >>> .v-input__slot {
    border-radius: 8px;
}
</style>
